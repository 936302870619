@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
  }

  h1 {
    @apply text-4xl;
  }
  h2 {
    @apply text-3xl;
  }
  h3 {
    @apply text-2xl;
  }
  h4 {
    @apply text-xl;
  }
  h5 {
    @apply text-lg;
  }
  h6 {
    @apply text-base;
  }
  p {
    @apply text-base;
  }

  .spinner {
    @apply border-2 border-t-transparent border-current border-solid rounded-full animate-spin;
    &-xs {
      @apply w-3 h-3;
    }
    &-sm {
      @apply w-4 h-4;
    }
    &-md {
      @apply w-6 h-6;
    }
    &-lg {
      @apply w-8 h-8;
    }
    &-xl {
      @apply w-10 h-10;
    }
  }

  .btn {
    @apply text-white text-base outline-none text-center flex items-center justify-center font-medium;
    &-xs {
      @apply px-2 h-6 text-xs;
    }
    &-sm {
      @apply px-3 h-8 text-sm;
    }
    &-md {
      @apply px-4 h-10 text-base;
    }
    &-lg {
      @apply px-5 h-12 text-lg;
    }
    &-xl {
      @apply px-6 h-14 text-xl;
    }
    &-primary {
      @apply bg-primary-600 hover:bg-primary-500;
    }
    &-secondary {
      @apply bg-gray-300 hover:bg-gray-100 text-gray-700;
    }
    &-success {
      @apply bg-green-600 hover:bg-green-700 text-gray-100;
    }
    &-danger {
      @apply bg-red-600 hover:bg-red-700 text-gray-100;
    }
    &-transparent {
      @apply bg-transparent hover:bg-black hover:bg-opacity-10;
    }
    &-disabled {
      @apply bg-gray-100 hover:bg-gray-100 text-gray-400 pointer-events-none;
    }
  }

  .textarea {
    @apply p-3 bg-white text-base w-full;
    textarea {
      @apply w-full outline-none bg-transparent;
    }

    .card & {
      @apply bg-gray-200;
    }
  }

  .input {
    @apply p-1 w-full bg-white text-black text-base overflow-hidden text-center flex items-center justify-center;
    input {
      @apply w-full outline-none bg-transparent;
    }
    &-xs {
      @apply h-4 text-xs;
    }
    &-sm {
      @apply h-6 text-sm;
    }
    &-md {
      @apply h-10 text-base;
    }
    &-lg {
      @apply h-11 text-lg;
    }
    &-xl {
      @apply h-12 text-xl;
    }
    &-disabled {
      @apply bg-opacity-50 hover:bg-opacity-50 pointer-events-none;
    }
    &-error {
      @apply bg-red-200 border-red-700 border;
      input {
        @apply outline-none;
      }
    }

    .card & {
      @apply bg-gray-200 text-black;
    }
  }

  .sidebar {
    @apply w-16 h-screen bg-black text-white shadow-md overflow-hidden relative;

    .sidebar-content {
      @apply overflow-y-auto;
      height: calc(100% - 4.5rem);
    }
  }

  .card {
    @apply p-4 bg-white text-black shadow w-full relative rounded-sm;
  }

  hr {
    @apply border w-full outline-none border-gray-300 bg-gray-300 my-2;
  }
}

html,
body {
  overflow-x: hidden !important;
  width: 100vw;
  background-color: #333333;
  color: white;

  @media print {
    background-color: white !important;
    size: 4.25in 8.5in;
  }
}

* {
  font-family: "Inter", sans-serif !important;
}

.routes-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}
.routes {
  flex: 1;
  width: calc(100vw - 4rem);
  overflow-x: hidden;
}

.page {
  padding: 1.5rem;
  min-height: 100vh;
  overflow-x: hidden;
}
